.icon-nuwton { background-image: url('../../../public/nuwton-logo.png') }
.icon-openw { background-image: url('../../../public/openw-logo.png') }
.icon-nswap { background-image: url('../../../public/nswap-logo.png') }
.icon-right-arrow { background-image: url('../../../public/arrow-icon-blue.png') }
.icon-down-arrow { background-image: url('../../../public/down-arrow-icon.png') }

.marketplace {
	font-weight: 400;
	font-size: 20px;
	line-height: 40px;
	color: #56C2FF;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.marketplace-link {
	width: 200px;
	display: flex;
	align-items: center;
	gap: 20px;
	position: relative;
}

.marketplace .icon.icon-right-arrow {
	width: 8px;
	height: 14px;
	right: 20px;
	position: absolute;
}

.marketplace .icon.icon-down-arrow {
	width: 14px;
	height: 8px;
	right: 20px;
	position: absolute;
}

.marketplace .context-menu {
	background: #F5F5F5;
	position: absolute;
	width: 180px;
  border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	top: 20px;
	left: 180px;
	display: none;
	z-index: 10;
}

.marketplace.up .context-menu {
	top: -260px;
}

.context-menu-item {
	/* opacity: 0.5; */
	height: 70px;
	display: flex;
	align-items: center;
	gap: 5px;
}

.context-menu-item .icon {
	width: 32px;
	height: 32px;
  margin-left: 25px;
}

.context-menu a div {
  color: #000000;
}

.context-menu a:hover {
  background: #EAEAEA;
}

.menu {
	display: flex;
	align-items: center;
	gap: 20px;
}

.menu .icon {
	width: 32px;
	height: 32px;
}

@media (max-width: 600px) {
	.marketplace {
  	font-size: 12px;
		line-height: 24px;
  }

	.marketplace .icon.icon-right-arrow {
		width: 4px;
		height: 7px;
		right: 10px;
		position: absolute;
	}

	.marketplace .icon.icon-down-arrow {
		width: 7px;
		height: 4px;
		right: 10px;
		position: absolute;
	}

	.marketplace-link {
		width: 130px;
	}

	.context-menu {
		left: -25px;
	}

	.menu .icon {
		width: 28px;
		height: 28px;
	}
}