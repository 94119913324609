.main {
	margin: auto;
	text-align: center;
}

.main .value-input {
	line-height: 50px;
	font-size: 20px;
	background: #F5F5F5;
	border-radius: 50px;
	border: 1px solid #ECECEC;
	width: 640px;
	height: 60px;
	padding: 0 20px 0 60px;
}

.main .value-input::placeholder {
	opacity: 0.3;
}

.main .btn {
	margin: 10px auto;
}

.main .btn-group .btn {
	padding-left: 7rem;
	padding-right: 7rem;
}

.main .input-group {
	margin: auto;
	position: relative;
	width: fit-content;
	margin-top: 40px;
	margin-bottom: 40px;
}

.input-group .btn {
	position: absolute;
	top: -2px;
	right: 10px;
	/* background: linear-gradient(360deg, #4D7FFF 0%, #3CBDFF 89.17%); */
	border-radius: 10px;
	border: none;
	padding: 10px 0;
	font-size: 20px;
	width: 120px;
	border-radius: 20px;
}

.input-group .icon {
	width: 25px;
	height: 25px;
	position: absolute;
	top: 20px;
	left: 20px;
}

.icon-check { background-image: url('../../../public/check-icon.png') }
.icon-warn { background-image: url('../../../public/warn-icon.png') }

.section.application .validation-error {
	color: #FFBD3D;
}

.section.application .validation-error .icon,
.section.application .whitelist-result .icon {
	width: 11px;
	height: 11px;
	display: inline-block;
	margin-right: 8px;
}

.hero-title {
	font-weight: 800;
	font-size: 80px;
	color: #1C549E;
	margin: 0 0;
	line-height: 98px;
}

.hero-title>span {
	color: var(--theme-color);
}

.hero-sub-title {
	font-size: 45px;
	margin: 1.6rem;
	color: var(--theme-color);
}

.bullet-points {
	color: #1C549E;
	margin: 15px 0 45px;
	White-space: nowrap;
	display: inline-flex;
	gap: 30px;
}

.check-mark {
	background-image: url('../../../public/check-blue.png');
	width: 20px;
	height: 20px;
	background-size: contain;
	display: inline-block;
	vertical-align: bottom;
	margin-right: 8px;
}

.point-item {
	font-size: 18px;
	font-weight: 600;
}

.btn.btn-secondary.white {
	margin-top: 30px;
}

.main .input-group {
	margin-top: 20px;
	margin-bottom: 20px;
}

.main .notice {
	color: #1C549E;
	font-size: 15px;
	margin-top: 15px;
}

.main .text {
	text-align: center;
	width: 500px;
	color: #1C549E;
	font-weight: 500;
	font-size: 18px;
	line-height: 30px;
	margin: 40px auto;
}

.main .text2 {
	text-align: center;
	width: 500px;
	color: #1C549E;
	font-weight: 600;
	font-size: 20px;
	line-height: 23px;
	margin: auto;
}

.main .text3 {
	text-align: center;
	width: 750px;
	color: black;
	font-weight: 500;
	font-size: 20px;
	line-height: 40px;
	margin: 20px auto;
}

.main .image {
	width: 120px;
	height: 147px;
	background-size: contain;
	margin: auto;
}

.image.logo {
	background-image: url('../../../public/twige.png');
}

.image.twit {
	background-image: url('../../../public/nft-twit.png');
	margin-bottom: 40px;
	width: 72px;
	height: 87px;
}

.icon-twitter-btn {
	background-image: url('../../../public/twitter-logo.png');
	width: 25px;
	height: 25px;
	background-size: contain;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
	margin-bottom: 3px;
}

.emoji {
	background-image: url('../../../public/emoji.png');
	width: 28px;
	height: 28px;
	display: inline-block;
	background-size: contain;
	margin-right: 5px;
	margin-bottom: 3px;
	vertical-align: middle;
}

@media (max-width: 980px) {
	.hero-title {
		font-size: 70px;
		line-height: 80px;
	}

	.hero-sub-title {
		font-size: 35px;
	}

	.bullet-points {
		margin: 10px 0 35px;
		gap: 20px;
	}

	.point-item {
		font-size: 15px;
	}

	.main .application .value-input {
		width: 500px;
		font-size: 18px;
	}

	.input-group .btn {
		top: 2px;
		padding: 10px;
		font-size: 16px;
		width: 80px;
	}

	.main .text3 {
		width: 500px;
		font-size: 18px;
		line-height: 25px;
		margin: 15px auto;
	}
}

@media (max-width: 600px) {
	.main {
		padding: 0px 30px;
	}

	.main .application .value-input {
		width: 320px;
		height: 40px;
		border-radius: 17px;
		border: 3px solid #FFFFFF;
		line-height: 30px;
		font-size: 10px;
		padding: 0 20px 0 30px;
	}

	.input-group .icon {
		width: 15px;
		height: 15px;
		top: 15px;
		left: 12px;
	}

	.input-group .btn {
		top: 2px;
		padding: 5px;
		font-size: 10px;
		width: 45px;
	}

	.hero-title {
		font-size: 42px;
		line-height: 35px;
	}

	.hero-sub-title {
		font-size: 20px;
	}

	.check-mark {
		width: 12px;
		height: 12px;
		margin-right: 3px;
	}

	.bullet-points {
		margin: 10px 0 20px;
		gap: 12px;
	}

	.point-item {
		font-size: 8px;
	}

	.btn.btn-secondary.white {
		margin: 10px auto;
		font-size: 12px;
	}

	.main .notice {
		font-size: 10px;
		margin-top: 10px;
	}
	
	.main .text {
		font-size: 12px;
		line-height: 20px;
		margin: 20px auto;
		width: 320px;
	}
	
	.main .text2 {
		width: 320px;
		font-size: 12px;
		line-height: 18px;
	}
	
	.main .text3 {
		width: 320px;
		font-size: 12px;
		line-height: 20px;
		margin: 10px auto;
	}

	.icon-twitter-btn {
		width: 15px;
		height: 15px;
		margin-right: 5px;
		margin-bottom: 3px;
	}
	
	.emoji {
		width: 15px;
		height: 15px;
		margin-right: 5px;
		margin-bottom: 3px;
	}
}

@media (max-width: 480px) {
	.main .application .value-input {
		width: 280px;
	}
}

@media (max-width: 360px) {
	.main .application .value-input {
		width: 240px;
	}
}