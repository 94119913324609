.app {
	min-height: 100vh;
	overflow-x: hidden;
	/* color: #FFFFFF; */
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0;
}

.content {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

a {
	text-decoration: none;
}

.btn {
	width: 400px;
	padding: 1rem 2rem;
	border-radius: 42px;
	font-weight: 500;
	font-size: 20px;
	cursor: pointer;
}

.btn-primary {
	background: rgba(0, 164, 249, 0.8);
	color: #FFFFFF;
}

.btn-primary:hover {
	background: #3A41D0;
}

.btn-secondary {
	/* color: #FFFFFF; */
	border: 1px solid #e9e9e9;
	/* background-color: transparent; */
}
.btn-secondary:hover {
	background: rgba(255, 255, 255, 0.2)
}

.btn.disabled {
	cursor: not-allowed;
	filter: opacity(0.5);
	background-color: #8C8DAD;
}

.btn-group {
	display: flex;
	margin: auto;
}

.icon {
	background-size: contain;
}

.nowrap {
	white-space: nowrap;
}

@media (max-width: 980px) {
	.btn {
		width: 350px;
		padding: 0.8rem 1.8rem;
	}
}

@media (max-width: 600px) {
	.btn {
		width: 200px;
		padding: 0.3rem 0.8rem;
		font-size: 12px;
	}
}
